<template>
  <b-card :title="title">
    <hospedajes-reports-table
      :key="'sent-hospedajes-reports-table'"
      :items="items"
      :loading="loading"
      :status="'SENT'"
    />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import HospedajesReportsTable from "@/views/administration/hospedajes-reports/components/HospedajesReportsTable.vue";
import {} from "@/utils/formatters";
import {} from "@/utils/methods";

export default {
  components: {
    BCard,
    HospedajesReportsTable,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    loading() {
      return this.$store.getters["bookings/loading"];
    },
  },
};
</script>
