<template>
  <b-card :title="title">
    <b-row class="my-2">
      <b-col cols="12">
        <b-button
          class="mr-50"
          :disabled="loading || !items.length"
          @click="generateHospedajesReport"
        >
          Descarregar XML
        </b-button>
        i puja'l a la
        <a
          href="https://sede.mir.gob.es/opencms/export/sites/default/es/procedimientos-y-servicios/hospedajes-y-alquiler-de-vehiculos/"
          target="_blank"
        >
          pàgina del Ministerio de Interior
        </a>
      </b-col>
    </b-row>

    <hospedajes-reports-table
      :key="'unsent-hospedajes-reports-table'"
      :items="items"
      :loading="loading"
      :status="'UNSENT'"
      @items-selected="onItemsSelected"
    />

    <b-row class="mt-2">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          :disabled="loading || !selectedItems.length"
          variant="primary"
          @click="updateBookingStats"
        >
          Marca com a enviat
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import HospedajesReportsTable from "@/views/administration/hospedajes-reports/components/HospedajesReportsTable.vue";
import { saveAs } from "file-saver";
import * as JSZip from "jszip";
import {
  formatDateObjectToDatabaseDate,
  formatDateObjectToYmdDate,
} from "@/utils/formatters";
import {
  getHospedajesBookingParteViajerosXml,
  notifyError,
  notifySuccess,
} from "@/utils/methods";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    HospedajesReportsTable,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["bookings/loading"];
    },
    hospedajesXmlReports() {
      if (!this.selectedItems.length) return null;

      console.log("selectedItems length", this.selectedItems.length);

      const reportableItems = this.selectedItems.filter((item) => {
        return (
          item.reportableGuests?.length && item.hospedajesAccommodationCode
        );
      });
      if (!reportableItems.length) return null;

      const reports = [];

      // Create xml report for each booking with reportable guests
      reportableItems.forEach((item) => {
        const report = getHospedajesBookingParteViajerosXml(item);
        if (report) reports.push(report);
      });

      return reports;
    },
  },
  methods: {
    onItemsSelected(items) {
      this.selectedItems = items;
    },
    generateHospedajesReport() {
      this.$store.dispatch("app/setLoading", true);

      const xmlReports = this.hospedajesXmlReports;
      const xmlFiles = [];

      // for each report generate a xml file, then compress all the generated xml files in a zip file and download it
      xmlReports.forEach((report, index) => {
        const blob = new Blob([report], {
          type: "text/xml; charset=utf-8",
        });
        const fileName = `parte-viajeros-${formatDateObjectToYmdDate(
          new Date()
        )}-${index + 1}.xml`;
        xmlFiles.push({ blob, fileName });
      });

      const zip = new JSZip();
      xmlFiles.forEach((file) => {
        zip.file(file.fileName, file.blob);
      });

      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "parte-viajeros.zip");
        this.$store.dispatch("app/setLoading", false);
      });
    },
    updateBookingStats() {
      if (!this.selectedItems.length) return;

      this.$store.dispatch("app/setLoading", true);

      const promises = [];

      this.selectedItems.forEach((booking) => {
        promises.push(
          this.$store.dispatch("booking/updateStats", {
            uuid: booking.bookingStatsUuid,
            hospedajesReportSent: true,
            hospedajesReportSentDate: formatDateObjectToDatabaseDate(
              new Date()
            ),
          })
        );
      });

      Promise.all(promises)
        .then(() => {
          notifySuccess(
            "Reserves actualitzades",
            "Les reserves seleccionades han estat marcades com a enviades a Hospedajes"
          );
          this.selectedItems = [];
          this.$emit("updated");
        })
        .catch((error) => notifyError("Error", error))
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
