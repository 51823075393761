<template>
  <b-tabs no-fade>
    <b-tab active>
      <template #title>
        <feather-icon class="mb-25 mb-lg-none" icon="ClockIcon" />
        <span class="d-none d-lg-block">Pendents d'enviar</span>
      </template>
      <unsent-hospedajes-reports-list
        title="Hospedajes pendents d'enviar o amb enviaments erronis"
        :items="parsedUnsentHospedajesReports"
        @updated="onBookingsUpdated"
      />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon class="mb-25 mb-lg-none" icon="CheckIcon" />
        <span class="d-none d-lg-block">Enviats</span>
      </template>
      <sent-hospedajes-reports-list
        title="Hospedajes enviats"
        :items="parsedSentHospedajesReports"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import UnsentHospedajesReportsList from "@/views/administration/hospedajes-reports/components/UnsentHospedajesReportsList.vue";
import SentHospedajesReportsList from "@/views/administration/hospedajes-reports/components/SentHospedajesReportsList.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BTabs,
    BTab,
    UnsentHospedajesReportsList,
    SentHospedajesReportsList,
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    unsentHospedajesReports() {
      return this.$store.getters["bookings/unsentHospedajesReports"];
    },
    parsedUnsentHospedajesReports() {
      if (!this.unsentHospedajesReports.length) return [];
      return this.unsentHospedajesReports
        .filter((booking) => {
          return (
            !booking.contract || !!booking.contract?.guestsRegistrationProvided
          );
        })
        .map((booking) => {
          return {
            uuid: booking.uuid || null,
            date: booking.date || null,
            localizator: booking.localizator || null,
            email: booking.email || null,
            source: booking.source || null,
            highlighted: booking.highlighted || false,
            client: booking.client?.fullName || null,
            clientUuid: booking.client?.uuid || null,
            accommodation: booking.accommodation?.name || null,
            accommodationUuid: booking.accommodation?.uuid || null,
            hospedajesOwnerCode:
              booking.accommodation?.hospedajesOwnerCode || null,
            hospedajesAccommodationCode:
              booking.accommodation?.hospedajesAccommodationCode || null,
            hospedajesUser: booking.accommodation?.hospedajesUser || null,
            hospedajesPassword:
              booking.accommodation?.hospedajesPassword || null,
            checkin: booking.checkin || null,
            checkout: booking.checkout || null,
            reportableGuests: booking.guests,
            bookingStatsUuid: booking.stats?.uuid || null,
            hospedajesReportSent: booking.stats?.hospedajesReportSent || null,
            hospedajesReportError: booking.stats?.hospedajesReportError || null,
            hospedajesReportErrorMessage:
              booking.stats?.hospedajesReportErrorMessage || null,
            hospedajesReportCommunicationId:
              booking.stats?.hospedajesReportCommunicationId || null,
          };
        });
    },
    sentHospedajesReports() {
      return this.$store.getters["bookings/sentHospedajesReports"];
    },
    parsedSentHospedajesReports() {
      if (!this.sentHospedajesReports.length) return [];
      return this.sentHospedajesReports.map((booking) => {
        return {
          uuid: booking.uuid || null,
          date: booking.date || null,
          localizator: booking.localizator || null,
          source: booking.source || null,
          highlighted: booking.highlighted || false,
          client: booking.client?.fullName || null,
          clientUuid: booking.client?.uuid || null,
          accommodation: booking.accommodation?.name || null,
          accommodationUuid: booking.accommodation?.uuid || null,
          hospedajesOwnerCode:
            booking.accommodation?.hospedajesOwnerCode || null,
          hospedajesAccommodationCode:
            booking.accommodation?.hospedajesAccommodationCode || null,
          hospedajesUser: booking.accommodation?.hospedajesUser || null,
          hospedajesPassword: booking.accommodation?.hospedajesPassword || null,
          checkin: booking.checkin || null,
          checkout: booking.checkout || null,
          reportableGuests: booking.guests,
          bookingStatsUuid: booking.stats?.uuid || null,
          hospedajesReportSent: booking.stats?.hospedajesReportSent || null,
          hospedajesReportError: booking.stats?.hospedajesReportError || null,
          hospedajesReportErrorMessage:
            booking.stats?.hospedajesReportErrorMessage || null,
          hospedajesReportCommunicationId:
            booking.stats?.hospedajesReportCommunicationId || null,
        };
      });
    },
  },
  created() {
    this.fetchUnsentHospedajesReports();
    this.fetchSentHospedajesReports();
  },
  methods: {
    fetchUnsentHospedajesReports() {
      this.$store
        .dispatch("bookings/fetchBookingsWithUnsentHospedajesReport")
        .catch(() => {
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          );
        });
    },
    fetchSentHospedajesReports() {
      this.$store
        .dispatch("bookings/fetchBookingsWithSentHospedajesReport")
        .catch(() => {
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          );
        });
    },
    onBookingsUpdated() {
      this.fetchUnsentHospedajesReports();
      this.fetchSentHospedajesReports();
    },
  },
};
</script>
